<template>
  <!-- Start Content-->
  <div>
    <div class="alert alert-warning" v-if="downloadAlert">{{ downloadAlert }}</div>

    <div class="responsive-table-plugin btn-toolbar align-items-center justify-content-between">
      <div class="form-inline md-form form-sm toolbar-search">
        <label>
          <div class="language" v-html="lan.company_name"></div>:
        </label>
        <input
          class="form-control mr-1 w-80"
          type="search"
          placeholder="Search"
          aria-label="Search"
          v-model="listStrage.query.corpname"
          @keyup.enter="search"
        />
      </div>

      <!-- 2 -->
      <div class="form-inline md-form form-sm toolbar-search">
        <label>
          <div class="language" v-html="lan.areaFrom"></div>:
        </label>
        <input
          style="width:90px"
          class="form-control mr-1 w-40"
          type="number"
          placeholder
          aria-label
          v-model="listStrage.query.areafrom"
          @keyup.enter="search"
        />
      </div>
      <!-- 2 -->

      <!-- 3 -->
      <div class="form-inline md-form form-sm toolbar-search">
        <label>
          <div class="language" v-html="lan.areaTo"></div>:
        </label>
        <input
          style="width:90px"
          class="form-control mr-1 w-40"
          type="number"
          placeholder
          aria-label
          v-model="listStrage.query.areato"
          @keyup.enter="search"
        />
      </div>
      <!-- 3 -->

      <!-- 4 -->
      <div class="form-inline md-form form-sm toolbar-search">
        <label>
          <div class="language" v-html="lan.move_date"></div>:
        </label>
        <select class="form-control" v-model="listStrage.query.movedate">
          <option value>{{lan.all}}</option>
          <option
            v-for="(item,index) in moveingTypeList"
            :key="index"
            :value="item.value"
            :selected="{true : index == 1}"
          >{{item.text}}</option>
        </select>
      </div> 
      <!-- 4 -->

      <div class="form-inline toolbar-download">
        <button type="button" class="btn btn-primary" @click="search">
          <i class="fas fa-search" aria-hidden="true"></i>
        </button>
      </div>

      <div class="form-inline toolbar-display">
        <div class="mr-2 toolbar-display-count">
          <span class="mr-1">
            <div class="lang" v-html="lan.limit_count"></div>
          </span>
          <div>
            <select class="form-control mt-0" v-model.lazy="listStrage.limit" @change="search">
              <option>10</option>
              <option>50</option>
              <option>100</option>
              <option>200</option>
              <option>500</option>
            </select>
          </div>
        </div>

        <div class="btn-group dropdown-btn-group">
          <button
            type="button"
            class="btn btn-white waves-effect dropdown-toggle"
            @click="toggleDDMenu"
          >
            <div class="lang" v-html="lan.show_item"></div>
          </button>

          <transition name="fade" mode="out-in">
            <div class="ddmenu-modal" v-show="ddMenu">
              <div class="ddmenu-bg" @click="toggleDDMenu"></div>
              <div class="ddmenu">
                <div class="d-flex justify-content-center p-5" v-if="isLoading">
                  <div class="spinner-border m-12" role="status">
                    <div class="sr-only">
                      <div class="lang" v-html="lan.loading"></div>...
                    </div>
                  </div>
                </div>
                <div v-else>
                  <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light btn-dd-close"
                    @click="toggleDDMenu"
                  >
                    <i class="mdi mdi-close"></i>
                  </button>
                  <ul class="dropdown-menu show">
                    <li
                      class="checkbox-row"
                      v-for="tableHeader in tableHeaders"
                      :key="tableHeader"
                    >
                      <input
                        type="checkbox"
                        :id="'table-toggle-' + tableHeader"
                        :value="tableHeader"
                        v-model.lazy="checkKeys"
                      />
                      <label :for="'table-toggle-' + tableHeader">{{ $language(tableHeader) }}</label>
                    </li>
                  </ul>
                  <div class="btn-group pull-right">
                    <button type="button" class="btn btn-primary" @click="allCheck">
                      <i class="mdi mdi-check-box-outline"></i>
                      <div class="lang" v-html="lan.check_all"></div>
                    </button>
                    <button type="button" class="btn btn-danger" @click="allUncheck">
                      <i class="mdi mdi-checkbox-blank-off-outline"></i>
                      <div class="lang" v-html="lan.uncheck"></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only">
          <div class="lang" v-html="lan.loading"></div>...
        </span>
      </div>
    </div>
    <div v-else-if="!jsonErr && !checkKeys.length">
      <!-- 表示項目無し -->
      <code>
        <div class="lang" v-html="lan.no_display_item_selected"></div>
      </code>
    </div>
    <div class="responsive-table-plugin" v-else-if="!jsonErr">
      <div class="table-rep-plugin">
        <div class="table-responsive" style="transform: scaleY(-1);">
          <table class="table table-striped focus-on" style="transform: scaleY(-1);">
            <thead>
              <tr>
                <th
                  v-for="tableHeader in tableHeaders"
                  :key="tableHeader"
                  :class="'table-row-' + tableHeader"
                  v-show="checkKeys.includes(tableHeader)"
                >{{ lan[tableHeader] }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="jsonObj.data.length && checkKeys.length">
              <tr
                v-for="(obj, idNum) in listData"
                :key="idNum"
         
                @click="toggleFocus(idNum)"
                @child="toggleFocus(idNum)"
              >
                <list-table-row :row="obj" :checkList="checkKeys" />

                <td class="table-focus-on-td">
                  <div style="display: flex;">
                    <transition transition name="slide-btn"  v-if="obj.hide_recommend == '0'">
                      <router-link
                        v-if="isFocus === idNum"
                        :to="`/report/corp_neet_info?corpNumber=${obj.corpNumber}`"
                        class="btn btn-default"
                      >
                        <div class="lang">{{$language('物件推荐')}}</div>
                        <i class="mdi mdi-arrow-right-bold"></i>
                      </router-link>
                    </transition>
                    <div style="margin-left:5px"></div>
                    <transition transition name="slide-btn">
                      <router-link
                        v-if="isFocus === idNum"
                        :to="`/${viewParams.tolink}/edit?dataId=${obj.id}`"
                        class="btn btn-default"
                      >
                        <div class="lang" v-html="lan.detail"></div>
                        <i class="mdi mdi-arrow-right-bold"></i>
                      </router-link>
                    </transition>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
        <div class="p-2">
            <code v-if="!jsonObj.total_count">
              <div class="lang" v-html="lan.no_data"></div>
            </code>
          </div>
        <!-- end .table-responsive -->
      </div>
      <!-- end .table-rep-plugin-->
      <div class="d-flex justify-content-end mt-1" v-if="jsonObj.total_count">
        <span>{{ (listStrage.paged - 1) * listStrage.limit + 1 }}</span>
        &nbsp;~&nbsp;
        <span>{{ (listStrage.paged - 1) * listStrage.limit + jsonObj.data.length }}</span>
        &nbsp;/&nbsp;
        <span>{{ jsonObj.total_count }}</span>
      </div>
      <div class="d-flex justify-content-end mt-1" v-else>
        <span>0</span>
        &nbsp;~&nbsp;
        <span>0</span>
        &nbsp;/&nbsp;
        <span>0</span>
      </div>

      <ul
        class="pagination pagination-split justify-content-end flex-wrap footable-pagination mt-1"
        v-if="jsonObj.total_count"
      >
        <li class="footable-page-arrow mb-1">
          <a href="#" @click.prevent.stop="pagenateKey('first')">«</a>
        </li>
        <li class="footable-page-arrow mb-1">
          <a href="#" @click.prevent.stop="pagenateKey('prev')">‹</a>
        </li>
        <li
          class="footable-page mb-1"
          :class="{'active': num === listStrage.paged, 'hide': pageHide(num) }"
          v-for="num in maxPage"
          :key="num"
        >
          <a href="#" @click.prevent.stop="pagenateNum(num)">{{ num }}</a>
        </li>
        <li class="footable-page-arrow mb-1">
          <a href="#" @click.prevent.stop="pagenateKey('next')">›</a>
        </li>
        <li class="footable-page-arrow mb-1">
          <a href="#" @click.prevent.stop="pagenateKey('last')">»</a>
        </li>
      </ul>
    </div>
    <!-- end .responsive-table-plugin-->
    <div v-else>
      <code>
        <div class="lang" v-html="lan.get_data_error"></div>
      </code>
    </div>
  </div>
  <!-- container -->
</template>

<script>
import ListTableRow from "./ListTableRowNeed.vue";
import axios from "axios";
import { inject } from "vue";
// import { json } from "body-parser";

export default {
  name: "ListTableNeed",
  props: {
    viewParams: {
      name: {
        type: String,
        default: "",
      },
      apiUrl: {
        type: String,
        default: "",
      },
      dataType: {
        type: String,
        default: 1,
      },
      articleView: String,
      articleParamId: String,
    },
  },
  components: {
    ListTableRow,
  },
  data() {
    return {
      offset: 0,
      listStrage: {
        query: {
          corpname: "",
          areafrom: "",
          areato: "",
          movedate: "",
        },
        limit: 100,
        paged: 1,
      },
      moveingTypeList: [
        { value: "1", text: this.$language('一ヶ月以内') },
        { value: "2", text: this.$language('一季度以内') },
        { value: "3", text: this.$language('半年内') },
        { value: "4", text: this.$language('１年') },
        { value: "5", text: this.$language('3年') },
        { value: "6", text: this.$language('5年') },
      ],
      maxPage: 0,
      checkKeys: [],
      jsonObj: {
        offset: 0,
        count: 0,
        total_count: 0,
        data: [Object],
      },
      tableHeaders: [],
      listData: [Object],
      isFocus: -1,
      ddMenu: false,
      jsonErr: false,
      isLoading: true,
      downloadAlert: "",
      lan: inject("global").language_data,
      lan_help: inject("global").language_help_data,
    };
  },
  mounted() {
    if (localStorage.getItem(this.viewParams.name + "_limit")) {
      this.listStrage.limit = localStorage.getItem(
        this.viewParams.name + "_limit"
      );
    }
    if (sessionStorage.getItem(this.viewParams.name + "_query")) {
      this.listStrage.query = JSON.parse(
        sessionStorage.getItem(this.viewParams.name + "_query")
      );
    }
    if (sessionStorage.getItem(this.viewParams.name + "_paged")) {
      this.listStrage.paged = Number(
        sessionStorage.getItem(this.viewParams.name + "_paged")
      );
    }
    if (localStorage.getItem(this.viewParams.name + "_tableHeaders")) {
      //読み込み時点のlocalstrageのtableheadersを取得
      let localTableHeaders = JSON.parse(
        localStorage.getItem(this.viewParams.name + "_tableHeaders")
      );
      console.log("数据：", this.listStrage.query);
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: (this.listStrage.paged - 1) * this.listStrage.limit, // 開始位置
            limit: this.listStrage.limit, //表示件数
            ...this.listStrage.query, //キーワード
          },
        })
        .then((response) => {
          this.jsonObj = response.data;
          this.tableHeaders = this.jsonObj.headers;
          this.listData = this.jsonObj.data;
          this.maxPage = Math.ceil(
            this.jsonObj.total_count / this.listStrage.limit
          );
          this.checkKeys = JSON.parse(
            localStorage.getItem(this.viewParams.name + "_checked")
          );

          // localstrageにないtableheaderをチェック済リストに追加
          let addTableHeaders = this.tableHeaders.filter(
            (item) => localTableHeaders.indexOf(item) == -1
          );
          if (addTableHeaders.length) {
            this.checkKeys.push.apply(this.checkKeys, addTableHeaders);
          }

          // tableheaderから無くなったheaderkeyがチェック済リストにあれば削除
          let delTableHeaders = this.checkKeys.filter(
            (item) => this.tableHeaders.indexOf(item) == -1
          );
          if (delTableHeaders.length) {
            let filterVal = this.checkKeys.filter(
              (item) => delTableHeaders.indexOf(item) == -1
            );
            this.checkKeys = filterVal;
          }

          // APIで取得した新しいtableheadersをlocalstrageに保存
          localStorage.setItem(
            this.viewParams.name + "_tableHeaders",
            JSON.stringify(this.tableHeaders)
          );

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error), (this.jsonErr = true), (this.isLoading = false);
        });
    } else {
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: (this.listStrage.paged - 1) * this.listStrage.limit, // 開始位置
            limit: this.listStrage.limit, //表示件数
            ...this.listStrage.query,
          },
        })
        .then(
          (response) => (
            (this.jsonObj = response.data),
            (this.tableHeaders = this.jsonObj.headers),
            (this.listData = response.data.data),
            (this.maxPage = Math.ceil(
              this.jsonObj.total_count / this.listStrage.limit
            )),
            (this.checkKeys =
              this.jsonObj.headers != undefined
                ? this.jsonObj.headers
                : Object.keys(this.listData[0])),
            localStorage.setItem(
              this.viewParams.name + "_tableHeaders",
              JSON.stringify(this.tableHeaders)
            ),
            (this.isLoading = false)
          )
        )
        .catch((error) => {
          console.log(error), (this.jsonErr = true), (this.isLoading = false);
        });
    }
  },
  methods: {
    toggleDDMenu: function () {
      this.ddMenu = !this.ddMenu;
    },
    allCheck: function () {
      this.checkKeys = this.tableHeaders;
    },
    allUncheck: function () {
      this.checkKeys = [];
    },
    toggleFocus: function (idNum) {
      this.isFocus = idNum;
    },

    getDataList(offset, limit, params_obj) {
      console.log(params_obj);
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: offset,
            limit: limit,
            ...params_obj,
          },
        })
        .then(
          (response) => (
            (this.jsonObj = response.data),
            (this.tableHeaders = this.jsonObj.headers),
            (this.listData = this.jsonObj.data),
            (this.maxPage = Math.ceil(
              this.jsonObj.total_count / this.listStrage.limit
            )),
            (this.listStrage.paged = 1),
            (this.isLoading = false)
          )
        )
        .catch((error) => {
          console.log(error), (this.jsonErr = true), (this.isLoading = false);
        });
    },

    search: function () {
      this.isLoading = true;
      localStorage.setItem(
        this.viewParams.name + "_limit",
        this.listStrage.limit
      );

      // let ss = this.listStrage.query

      // sessionStorage.setItem(
      //   this.viewParams.name + "_query",
      //   JSON.stringify(JSON.stringify(this.listStrage.query))
      // );
      sessionStorage.setItem(this.viewParams.name + "_paged", 1);
      this.getDataList(0, this.listStrage.limit, this.listStrage.query);
    },
    pagenateNum: function (num) {
      this.isLoading = true;
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: (num - 1) * this.listStrage.limit, //開始位置
            limit: this.listStrage.limit, //表示件数
            q: JSON.stringify(this.listStrage.query), //キーワード
          },
        })
        .then(
          (response) => (
            (this.jsonObj = response.data),
            (this.tableHeaders = this.jsonObj.headers),
            (this.listData = this.jsonObj.data),
            (this.maxPage = Math.ceil(
              this.jsonObj.total_count / this.listStrage.limit
            )),
            (this.listStrage.paged =
              Math.round(this.jsonObj.offset / this.listStrage.limit) + 1),
            sessionStorage.setItem(
              this.viewParams.name + "_paged",
              this.listStrage.paged
            ),
            (this.isLoading = false)
          )
        )
        .catch((error) => {
          console.log(error), (this.jsonErr = true), (this.isLoading = false);
        });
    },
    pagenateKey: function (key) {
      switch (key) {
        case "next":
          if (this.listStrage.paged < this.maxPage) {
            this.isLoading = true;
            this.offset = this.listStrage.limit * this.listStrage.paged;
          }
          break;
        case "prev":
          if (this.listStrage.paged > 1) {
            this.isLoading = true;
            this.offset = this.listStrage.limit * (this.listStrage.paged - 2);
          }
          break;
        case "first":
          if (this.listStrage.paged > 1) {
            this.isLoading = true;
            this.offset = 0;
          }
          break;
        case "last":
          if (this.listStrage.paged < this.maxPage) {
            this.isLoading = true;
            this.offset = this.listStrage.limit * (this.maxPage - 1);
          }
          break;
        default:
          this.isLoading = false;
          console.log("Non-existent key");
      }
      if (this.isLoading) {
        axios
          .get(this.viewParams.apiUrl, {
            params: {
              offset: this.offset, //開始位置
              limit: this.listStrage.limit, //表示件数
              ...this.listStrage.query, //キーワード
            },
          })
          .then(
            (response) => (
              (this.jsonObj = response.data),
              (this.tableHeaders = this.jsonObj.headers),
              (this.listData = this.jsonObj.data),
              (this.maxPage = Math.ceil(
                this.jsonObj.total_count / this.listStrage.limit
              )),
              (this.listStrage.paged =
                Math.round(this.jsonObj.offset / this.listStrage.limit) + 1),
              sessionStorage.setItem(
                this.viewParams.name + "_paged",
                this.listStrage.paged
              ),
              (this.isLoading = false)
            )
          )
          .catch((error) => {
            console.log(error), (this.jsonErr = true), (this.isLoading = false);
          });
      }
    },
    pageHide: function (num) {
      if (num < this.listStrage.paged - 4 || num > this.listStrage.paged + 4) {
        return true;
      }
    },
    listDownload: function () {
      let xmldata;
      axios
        .post(
          this.viewParams.apiUrl + "download",
          {
            data: this.listData,
            items: this.checkKeys,
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          xmldata = response.data;
          this.$nextTick(() => {
            let blob = new Blob([xmldata]);
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "corps.xlsx";
            link.click();
          });
          this.downloadAlert = "";
          this.isLoading = false;
        })
        .catch((error) => {
          this.downloadAlert = this.$language('ダウンロードに失敗しました。');
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  watch: {
    checkKeys(newCheckKeys) {
      localStorage.setItem(
        this.viewParams.name + "_checked",
        JSON.stringify(newCheckKeys)
      );
    },
    isLoading: function () {
      if (!this.isLoading) {
        this.$nextTick(() => {
          window.parent.postMessage(["h"], "*");
        });
      } else {
        //ローディング開始のタイミングでisFocusをリセット
        this.isFocus = -1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-inline {
  flex-wrap: nowrap;
}

.toolbar-download {
  margin-left: auto;
  margin-right: 1.5rem;
}

.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 576px) {
  .toolbar-search {
    flex: 1;
  }

  .toolbar-download {
    justify-content: flex-end;
    margin-bottom: 0.75rem;
    margin-right: 0;
    order: -1;
    width: 100%;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: 0.5em;
    width: 100%;
  }
}

.form-search {
  position: relative;
}

.form-search::before {
  content: "\f002";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  height: 1em;
  width: 1em;
}

.ddmenu-modal {
  position: relative;
  z-index: 128;
}

.ddmenu {
  background: #fff;
  border-radius: 0.15rem;
  left: 50% !important;
  padding: 2.5rem 2.5rem 1.5rem;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  width: 80vw;
  z-index: 16;
}

.ddmenu .btn-dd-close {
  position: absolute !important;
  right: -0.5rem;
  top: -0.5rem;
}

.dropdown-menu.show {
  align-content: flex-start;
  animation: none !important;
  display: flex;
  flex-wrap: wrap;
  left: auto !important;
  margin-bottom: 1rem;
  max-height: 40rem;
  overflow-y: scroll !important;
  position: static !important;
  top: auto !important;
  width: 100%;
}

.dropdown-menu.show li {
  flex: 0 1 32.5%;
  word-break: break-all;
}

.ddmenu-bg {
  background: rgba(0, 0, 0, 0.25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.fade-leave-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .dropdown-menu.show li {
    flex-basis: 50%;
  }
}

@media screen and (max-width: 500px) {
  .dropdown-menu.show li {
    flex-basis: 100%;
  }
}

.table-responsive {
  overflow: auto !important;
}

table.focus-on tbody tr:hover,
table.focus-on tfoot tr:hover {
  cursor: unset;
}

.table thead tr:last-child th {
  background: #fff;
  box-shadow: 0 -0.1rem #dee2e6 inset;
  border-bottom: none;
  position: sticky;
  top: -1px;
  z-index: 8;
}

.table.table-striped td {
  min-width: 14.5em;
  white-space: normal !important;
  word-wrap: break-word;
}

.table.table-striped .table-focus-on-td {
  align-items: center;
  min-width: auto;
  padding: 0.35rem !important;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: 0.15s;
}

// transition
.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}
</style>