<template>
 <div class="">
     <headers />
    <div class="mb" style="padding:15px;padding-top: 70px !important;">
      <div class="row">
        <div class="col-12">
          <div class="page-title-box on-toolbar">
            <h1 class="page-title">{{lan.customer_recommend_info}}</h1>
            <router-link to="/corp_need_info/edit" class="btn btn-primary ml-sm-4">{{lan.customer_info_input}}</router-link>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card-box">
            <list-table :viewParams="viewParams" />
          </div>
        </div>
      </div>
    </div>
  </div>
 
</template>

<script>
import Headers from '@/components/Headers.vue'
import ListTable from '@/components/ListTableNeed.vue';
import { inject } from 'vue';
export default {
  name: 'corp_need_info_list',
  components: {
    ListTable,Headers
  },
  data() {
    return { 
      viewParams: {
        name: 'corp_need_customer',
        apiUrl: process.env.VUE_APP_API2_URL_BASE+'/getCorpNeedCustomer',
        articleView: 'corp_need_info_edit',
        articleParamId: '_id',
        tolink:'corp_need_info',
        
      },
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      cur_lan:'jp',
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.page-title-box.on-toolbar {
  align-items: center;
  display: flex;
}

@media screen and (max-width: 576px) {
  .page-title-box.on-toolbar {
    justify-content: space-between;
  }
}
</style>